import { createWebHistory, createRouter } from "vue-router"
import Home from "../views/Home.vue"
import Works from "../views/Works.vue"
import Services from "../views/Services.vue"
import Faq from "../views/Faq.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },{
    path: "/works",
    name: "Works",
    component: Works,
  },{
    path: "/services",
    name: "Services",
    component: Services,
  },{
    path: "/faq",
    name: "Faq",
    component: Faq,
  }, {
    path: "/:pathMatch(.*)*",
    component: Home
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;