<template>
  <Header />
  <router-view />
  <Footer />
  <button type="button" v-show="scrollY > 300" @click="toTop" class="btn rounded-circle d-md-none fixed-bottom m-4 p-2" style="width: 3rem; height: 3rem; left: auto; background-color: #3397a5">
    <img class="w-100" src="@/assets/arrow-up.png" style="filter: invert(100%)" />
  </button>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  data: () => {
    return ({
      timer: null,
      scrollY: 0,
    })
  },
  methods: {
    handleScroll: function () {
      if (this.timer) return;
      this.timer = setTimeout(() => {
        this.scrollY = window.scrollY
        clearTimeout(this.timer)
        this.timer = null
      }, 100)
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  }
}
</script>

<style scope="global">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

#app {
  font-family: 'Noto Sans TC', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}

body {
  background-color: black;
}
</style>
