<template>
  <div class="container">
    <div class="row inner-header">
      <div
        class="col-12 col-md-3 txt-start d-grid align-items-center inner-header-left"
      >
        <h1 class="pb-2 pb-md-3 text-start">{{ title }}</h1>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
      <div class="col-md-9"></div>
    </div>

    <section :id="name" class="row inner">
      <div class="col-md-2 d-none d-md-block pt-4 position-relative">
        <img
          class="w-50 h-auto position-sticky"
          style="filter: none"
          src="@/assets/logoName.png"
        />
      </div>
      <div class="col-12 col-md-8">
        <slot />
      </div>
      <div class="col-md-2 d-none d-md-block pt-4">
        <span class="inner-sign">{{ name }}</span>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "InnerLayout",
  props: ["title", "name"],
  data: () => {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.inner-header {
  background: radial-gradient(
    26vw circle at 16vw 28vw,
    white 100%,
    transparent 0%
  );
  padding-bottom: 22vw;
  margin-bottom: -28vw;
  padding-top: 18vw;
}
.inner-header-left {
  padding-left: 5vw;
}
.inner-header h1 {
  color: black;
  font-size: 1.375rem;
}
.dot {
  height: 0.6rem;
  width: 0.6rem;
  background-color: black;
  border-radius: 50%;
  display: block;
  margin-bottom: 0.5rem;
  margin-left: 2.75rem;
}
.inner {
  padding-top: 300px;
  margin-top: -250px;
  margin-bottom: 200px;
  color: white;
}
.inner .position-sticky {
  top: 0;
  margin-bottom: 200px;
}
.inner .inner-sign {
  text-transform: uppercase;
  writing-mode: vertical-rl;
  font-size: 10rem;
  color: #3397a5;
  font-weight: bold;
  line-height: 10rem;
  letter-spacing: 1.5rem;
  margin-right: -60%;
  font-family: "Poppins", sans-serif;
}

@media (min-width: 320px) {
}
@media (min-width: 576px) {
  .container {
    max-width: 767px;
  }
  .inner-header {
    background: radial-gradient(
      18vw circle at 16vw 18vw,
      white 100%,
      transparent 0%
    );
    padding-bottom: 16vw;
    margin-bottom: -32vw;
    padding-top: 12vw;
  }
  .inner-header-left {
    padding-left: 9vw;
  }
  .inner-header h1 {
    font-size: calc(1.375rem + 0.7vw) !important;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 991px;
  }
  .inner-header {
    background: radial-gradient(
      18vw circle at 16vw 18vw,
      white 100%,
      transparent 0%
    );
    padding-bottom: 16vw;
    margin-bottom: -32vw;
    padding-top: 12vw;
  }
  .inner-header-left {
    padding-left: 9vw;
  }
  .inner-header h1 {
    font-size: calc(1.375rem + 0.5vw) !important;
  }
  .dot {
    margin-left: calc((1.375rem + 0.5vw) * 2);
  }
  .inner {
    background: radial-gradient(
      100vw circle at 70vw 75vw,
      #333 70%,
      transparent 0%
    );
    padding-bottom: 16vw;
    margin-bottom: -16vw;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1199px;
  }
  .inner-header {
    background: radial-gradient(
      16vw circle at 14vw 16vw,
      white 100%,
      transparent 0%
    );
    padding-bottom: 16vw;
    margin-bottom: -26vw;
    padding-top: 10vw;
  }
  .inner-header-left {
    padding-left: 7vw;
  }
  .inner-header h1 {
    font-size: calc(1.375rem + 1.4vw) !important;
  }
  .dot {
    margin-left: calc((1.375rem + 1.4vw) * 2);
  }
  .inner {
    background: radial-gradient(
      100vw circle at 70vw 70vw,
      #333 70%,
      transparent 0%
    );
    padding-bottom: 20vw;
    margin-bottom: -20vw;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
  .inner-header {
    background: radial-gradient(
      14vw circle at 12vw 14vw,
      white 100%,
      transparent 0%
    );
    padding-bottom: 14vw;
    margin-bottom: -20vw;
    padding-top: 10vw;
  }
  .inner-header-left {
    padding-left: 7vw;
  }
  .inner-header h1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .dot {
    margin-left: calc((1.375rem + 1.5vw) * 2);
  }
  .inner {
    background: radial-gradient(
      90vw circle at 50vw 65vw,
      #333 70%,
      transparent 0%
    );
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1920px;
  }
  .inner-header {
    background: radial-gradient(
      12vw circle at 10vw 12vw,
      white 100%,
      transparent 0%
    );
    padding-bottom: 12vw;
    margin-bottom: -18vw;
    padding-top: 8vw;
  }
  .inner-header-left {
    padding-left: 5vw;
  }
  .inner {
    background: radial-gradient(
      90vw circle at 40vw 65vw,
      #333 70%,
      transparent 0%
    );
  }
}
@media (min-width: 1800px) {
  .inner-header {
    background: radial-gradient(
      180px circle at 228px 180px,
      white 100%,
      transparent 0%
    );
    padding-bottom: 180px;
    margin-bottom: -288px;
    padding-top: 108px;
  }
  .inner-header-left {
    padding-left: 0;
    justify-content: center;
  }
  .inner-header h1 {
    font-size: 56px !important;
  }
  .dot {
    margin-left: 112px;
  }
  .inner {
    background: radial-gradient(
      1350px circle at 50% 950px,
      #333 70%,
      transparent 0%
    );
  }
}
</style>
