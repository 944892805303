<template>
  <InnerLayout title="服務項目" name="services">
    <h1 class="text-start padding-small">鐵材種類</h1>
    <div class="row materials padding-small">
      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/RoundIronBar.png" alt="">
        <h3>圓鐵</h3>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/SquareIronBar.png" alt="">
        <h3>方鐵</h3>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/FlatIronBar.png" alt="">
        <h3>扁鐵</h3>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/RoundIronTube.png" alt="">
        <h3>圓管</h3>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/SquareIronTube.png" alt="">
        <h3>方管</h3>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/FlatIronTube.png" alt="">
        <h3>扁管</h3>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/AngleBar.png" alt="">
        <h3>角鐵</h3>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/ChannelBar.png" alt="">
        <h3>槽鐵</h3>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/cShapedMetalBar.png" alt="">
        <h3>C型鋼</h3>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/HbeamBar.png" alt="">
        <h3>H型鋼</h3>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/IbeamBar.png" alt="">
        <h3>工字鐵</h3>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/RailSteel.png" alt="">
        <h3>鋼軌</h3>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/RollingSteelCone.png" alt="">
        <h3>扇型鋼板</h3>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <img src="@/assets/materials/RollingSteelPlate.png" alt="">
        <h3>長方形鋼板</h3>
      </div>
    </div>

    <h1 class="text-start padding-small">鐵材加工方向</h1>
    <div class="row rolling padding-small">
      <div class="col-12 text-start">
        <h3>H型鋼<span class="slash">/</span>工字鐵<div class="r-dot"></div><small>強軸</small></h3>
        <img src="@/assets/rolling/Hbeam1.png" alt="">
      </div>

      <div class="col-12 text-start">
        <h3>H型鋼<span class="slash">/</span>工字鐵<div class="r-dot"></div><small>弱軸</small></h3>
        <img src="@/assets/rolling/Hbeam2.png" alt="">
      </div>

      <div class="col-12 text-start">
        <h3>鋼軌<div class="r-dot"></div><small>側向加工</small></h3>
        <img src="@/assets/rolling/RailSteel1.png" alt="">
      </div>

      <div class="col-12 text-start">
        <h3>鋼軌<div class="r-dot"></div><small>向外加工</small></h3>
        <img src="@/assets/rolling/RailSteel2.png" alt="">
      </div>

      <div class="col-12 text-start">
        <h3>鋼軌<div class="r-dot"></div><small>向內加工</small></h3>
        <img src="@/assets/rolling/RailSteel3.png" alt="">
      </div>
    </div>
    <h1 class="text-start padding-small">加工項目</h1>
    <ul class="text-start padding-small items">
      <li>專業輪圓 / 滾員加工</li>
      <li>彎曲</li>
      <li>校直</li>
      <li>專業莫具開發</li>
      <li>螺旋梯鋼骨結構</li>
    </ul>
  </InnerLayout>
</template>

<script>
import InnerLayout from '../layout/Inner.vue';

export default {
  name: "Services",
  components: {
      InnerLayout
  },
  data: () => {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
h1 {
  color: #78c7d2;
  margin-bottom: 5rem;
  font-size: 3rem;
}
h1:not(:first-child) {
  margin-top: 10rem;
}
.dot {
  height: 0.3rem;
  width: 0.3rem;
  background-color: black;
  border-radius: 50%;
  display: block;
  margin-bottom: 0.5rem;
  margin-left: 2.75rem;
}
.materials > div {
  border: 1px solid gray;
  padding: 2rem;
}
.materials img {
  width: 100%;
  margin-bottom: 1rem;
}
.materials h3 {
  font-weight: 300;
  letter-spacing: 0.3rem;
}
.rolling > div:not(:last-child) {
  margin-bottom: 5rem;
}
.rolling h3 {
  color: #e9954e;
  font-weight: 200;
}
.rolling .slash {
  padding: 0 10px;
  font-size: 00.75rem;
}
.rolling .r-dot {
  width: 0.5rem;
  height: 0.5rem;
  border: 3px solid #78c7d2;
  border-radius: 50%;
  display: inline-block;
  margin: 0 7px 0 5px;
}
.rolling small {
  color: white;
}
.rolling img {
  max-height: 100%;
  max-width: 100%;
}
ul {
  margin: 0;
  padding: 0;
}
.items {
  font-size: 1.8rem;
  letter-spacing: 0.2rem;
  color: black;
  margin-top: -2rem;
}
.items li {
  list-style: none;
  font-weight: 300;
}
@media (min-width: 1800px) {
  .items::before {
    content: '';
    display: inline-block;
    width: 950px;
    height: 950px;
    border-radius: 50%;
    background-color: #ccc;
    position: absolute;
    z-index: -1;
    margin: -400px 0 0 -250px;
  }
  .items::after {
    content: '';
    display: inline-block;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: orange;
    position: absolute;
    margin: -70px 0 0 650px;
  }
}
@media (max-width: 768px) {
  .padding-small {
    padding: 0 2rem;
  }
}
</style>
