<template>
  <InnerLayout title="常見問題" name="faq">
    <ul>
      <li class="question">
        <h3 class="question-odd">請問可以幫忙訂購材料嗎？</h3>
        <p>可以，我們會請熟悉的材料廠幫忙報價，如圖面複雜，需要拆圖等，需要時長會比較多，如趕時間建議直接聯繫材料廠進行溝通。</p>
        <p>此外，當鋼鐵價格波動大時，確認報價單的時間會縮短，請盡早確認完畢回傳報價單，也可減少材料二次詢價的等待時間喔～</p>
      </li>
      <li class="question">
        <h3 class="question-even">請問詢價和製作流程為何？</h3>
        <p>可以，我們會請熟悉的材料廠幫忙報價，如圖面複雜，需要拆圖等，需要時長會比較多，如趕時間建議直接聯繫材料廠進行溝通。</p>
        <p>此外，當鋼鐵價格波動大時，確認報價單的時間會縮短，請盡早確認完畢回傳報價單，也可減少材料二次詢價的等待時間喔～</p>
      </li>
    </ul>
  </InnerLayout>
</template>

<script>
import InnerLayout from '../layout/Inner.vue';

export default {
  name: "Faq",
  components: {
      InnerLayout
  },
  data: () => {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.question {
  list-style: none;
  text-align: start;
  letter-spacing: 0.1rem;
  margin-bottom: 4rem;
}
.question h3 {
  font-size: 2.25rem;
  margin-left: 100px;
}
.question-odd::before {
  content: 'Q';
  color: white;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  background-color: #3397a5;
  display: inline-grid;
  align-items: center;
  text-align: center;
  margin-left: -100px;
  margin-right: 20px;
}
.question-even::before {
  content: 'Q';
  color: white;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  background-color: #e9954e;
  display: inline-grid;
  align-items: center;
  text-align: center;
  margin-left: -100px;
  margin-right: 20px;
}
.question p {
  margin-left: 100px;
  font-size: 1.5rem;
  font-weight: 200;
}
.question-odd {
  color: #3397a5;
}
.question-even {
  color: #e9954e;
}
</style>