<template>
  <footer class="footer text-white" :class="{ circle: currentPath !== '/' }">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-7 text-start">
          <h1 class="fw-bold">勝輝鋼鐵工業股份有限公司</h1>
          <h2>TEL: (04) 2622 4565</h2>
          <h2>FAX: (04) 2622 4566</h2>
          <h2>LINE ID: @kto1608u</h2>
          <h2>E-mail: sheng.huei215@gmail.com</h2>
          <h2>Address: 436 台中市清水區三田路 1-5 號</h2>
        </div>
        <div class="col-12 col-md-4 col-lg-2">
          <img class="w-100 qrcode-gray" src="@/assets/qrcode.jpeg" />
          <a
            class="btn bg-white w-100 mt-5 mt-lg-4 d-flex justify-content-center align-items-center"
            href="https://goo.gl/maps/ng3jyjGpkNgkMCyL6"
            target="_blank"
            >Google Map</a
          >
        </div>
        <div class="col-1 d-none d-lg-block"></div>
        <div class="col-2 d-none d-lg-block">
          <span v-if="currentPath === '/'" class="contact-sign d-none d-md-block">CONTACT</span>
        </div>
      </div>
      <div class="row py-5">
        <div class="col-12">
          <h2>© 2021 Sheng Huei ALL RIGHTS RESERVED</h2>
          <h2>DESIGNED BY Beka</h2>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data: function () {
    return {
      currentPath: ''
    }
  },
  mounted() {
    this.currentPath = window.location.pathname
  }
};
</script>

<style scoped>
h1 {
  font-size: 3rem;
  letter-spacing: 1px;
  margin-bottom: 20px;
}
h2 {
  line-height: 1.5rem;
  letter-spacing: 2.5px;
}
.qrcode-gray {
  filter: grayscale(1);
}
.contact-sign {
  writing-mode: vertical-rl;
  font-size: 10rem;
  color: #3397a5;
  font-weight: bold;
  line-height: 10rem;
  letter-spacing: 1.5rem;
  margin-top: -60vw;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}
@media (min-width: 320px) {
  .footer {
    background: none;
  }
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  .btn {
    height: 5rem;
    font-size: 1.8rem;
    letter-spacing: 2px;
  }
}
@media (min-width: 576px) {
  h1 {
    font-size: 2.5rem;
    letter-spacing: 5px;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .btn {
    font-size: 2rem;
  }
}
@media (min-width: 768px) {
  .footer {
    padding-top: 30vw;
    margin-top: -30vw;
    background: radial-gradient(
      80vw circle at 40% 60vw,
      #4d4d4d 70%,
      transparent 0%
    );
  }
  .circle::before {
	content: '';
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #e9954e;
	position: absolute;
    left: 20px;
    margin-top: -15vw;
  }
  .btn {
    height: 4rem;
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  .btn {
    height: 3rem;
    font-size: 1rem;
  }
  .circle::before {
    margin-top: -14vw;
  }
}
@media (min-width: 1200px) {
  .contact-sign {
    margin-top: -600px;
  }
}
@media (min-width: 1400px) {
}
@media (min-width: 1800px) {
  .footer {
    padding-top: 10vw;
    margin-top: -10vw;
    background: radial-gradient(
      80vw circle at 40% 60vw,
      #4d4d4d 70%,
      transparent 0%
    );
  }
  .circle::before {
    margin-top: 8vw;
  }
}
</style>
