<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark">
    <div class="container-fluid justify-content-between">
      <a href="/"><img class="navbar-toggler border-0" src="@/assets/LogoMobile.png" /></a>
      <button
        class="navbar-toggler border-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 mx-auto">
          <li class="nav-item">
            <a class="nav-link" :class="{ active: currentPath === '/about' }" aria-current="page" href="#">關於我們<span class="d-none d-md-block">ABOUT</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: currentPath === '/works' }" aria-current="page" href="/works">作品總覽<span class="d-none d-md-block">WORKS</span></a>
          </li>
          <li class="nav-item d-none d-md-block px-4">
              <a class="navbar-brand mx-auto" href="/">
                <img
                src="@/assets/logo.png"
                alt=""
                width="100"
                />
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: currentPath === '/services' }" aria-current="page" href="/services">服務項目<span class="d-none d-md-block">SERVICES</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: currentPath === '/faq' }" aria-current="page" href="#">常見問題<span class="d-none d-md-block">FAQ</span></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
  data: function () {
    return {
      currentPath: ''
    }
  },
  mounted() {
    this.currentPath = window.location.pathname
  }
};
</script>

<style scoped>
.bg-dark {
  background-color: black !important;
}
.navbar-light .navbar-nav .nav-link {
  color: white;
}
.navbar {
  margin-top: 2rem;
}
.navbar-toggler-icon {
  width: 2rem;
  height: 2rem;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.nav-link {
    display: inline-grid;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: 5px;
}
.nav-link span {
    margin-top: -5px;
    font-size: 1.2rem;
}
.navbar-dark .navbar-nav .nav-link {
  color: white;
}
.navbar-dark .navbar-nav .nav-link.active {
  color: rgb(255, 255, 255, .55);
}
@media (min-width: 320px) {
  .navbar-toggler {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .nav-link {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1.2rem;
  }
}
@media (min-width: 992px) {
  .nav-link {
    font-size: 1.5rem;
  }
}
</style>
