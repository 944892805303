<template>
  <div class="wrap">
    <span class="circle circle-1">
      <span class="circle circle-2">
        <span class="circle circle-3">
          <span class="circle circle-4">
            <span class="circle circle-5">
              <span class="circle circle-6"></span>
            </span>
          </span>
        </span>
      </span>
    </span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@function pow($base, $exponent) {
  $result: 1;
  @if $exponent > 0 {
    @for $_ from 1 through $exponent {
      $result: $result * $base;
    }
  }
  @return $result;
}

$phi: 1.6180339887498948482;

@function toPercent($num) {
  @return unquote($num * 100 + "%");
}

$diameter-1: 100%;
$diameter-2: toPercent(1 / pow($phi, 1));
$diameter-3: toPercent(pow($phi, 1) / pow($phi, 2));
$diameter-4: toPercent(pow($phi, 2) / pow($phi, 3));
$diameter-5: toPercent(pow($phi, 3) / pow($phi, 4));
$diameter-6: toPercent(pow($phi, 4) / pow($phi, 5));
$diameter-7: toPercent(pow($phi, 5) / pow($phi, 6));
$diameter-8: toPercent(pow($phi, 6) / pow($phi, 7));
$diameter-9: toPercent(pow($phi, 7) / pow($phi, 8));

$radius-2: (($diameter-1 / pow($phi, 1)) / 2);
$radius-3: (($diameter-1 / pow($phi, 2)) / 2);
$radius-4: (($diameter-1 / pow($phi, 3)) / 2);
$radius-5: (($diameter-1 / pow($phi, 4)) / 2);
$radius-6: (($diameter-1 / pow($phi, 5)) / 2);
$radius-7: (($diameter-1 / pow($phi, 6)) / 2);
$radius-8: (($diameter-1 / pow($phi, 7)) / 2);
$radius-9: (($diameter-1 / pow($phi, 8)) / 2);

@function trans($i) {
  $j: $i - 2;
  $k: $i - 1;
  $big: ($diameter-1 / pow($phi, $j));
  $small: ($diameter-1 / pow($phi, $k));
  @return (($big - $small) / 2);
}

$trans-2: trans(2);
$trans-3: trans(3);
$trans-4: trans(4);
$trans-5: trans(5);
$trans-6: trans(6);
$trans-7: trans(7);
$trans-8: trans(8);
$trans-9: trans(9);

.wrap {
  height: 100%;
  position: relative;
}
.wrap:before {
	content: "";
	float: left;
	padding-top: 100%;
}
.circle {
  display: block;
  box-sizing: border-box;
  border: 3px solid #fff;
  border-radius: 50%;
  position: absolute;
}
.circle-1 {
  height: $diameter-1;
  width: $diameter-1;
  transform: rotate(90deg);
}
.circle-2 {
  height: $diameter-2;
  width: $diameter-2;
  top: 19.09830056250528%;
  left: 19.09830056250528%;
  -webkit-animation: rot-2 6000ms infinite linear !important;
  animation: rot-2 6000ms infinite linear !important;
}
.circle-3 {
  height: $diameter-3;
  width: $diameter-3;
  top: 19.09830056250528%;
  left: 19.09830056250528%;
  -webkit-animation: rot-3 3000ms infinite linear !important;
  animation: rot-3 3000ms infinite linear !important;
}
.circle-4 {
  height: $diameter-4;
  width: $diameter-4;
  top: 19.09830056250528%;
  left: 19.09830056250528%;
  -webkit-animation: rot-4 2000ms infinite linear !important;
  animation: rot-4 2000ms infinite linear !important;
}
.circle-5 {
  height: $diameter-5;
  width: $diameter-5;
  top: 19.09830056250528%;
  left: 19.09830056250528%;
  -webkit-animation: rot-5 1500ms infinite linear !important;
  animation: rot-5 1500ms infinite linear !important;
}
.circle-6 {
  height: $diameter-6;
  width: $diameter-6;
  top: 19.09830056250528%;
  left: 19.09830056250528%;
  -webkit-animation: rot-6 1200ms infinite linear !important;
  animation: rot-6 1200ms infinite linear !important;
}
@-webkit-keyframes rot-2 {
  from {
    transform: rotate(0deg) translate(30.90169943749472%) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(30.90169943749472%) rotate(-360deg);
  }
}
@keyframes rot-2 {
  from {
    transform: rotate(0deg) translate(30.90169943749471%) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(30.90169943749471%) rotate(-360deg);
  }
}
@-webkit-keyframes rot-3 {
  from {
    transform: rotate(0deg) translate(30.90169943749471%) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(30.90169943749471%) rotate(-360deg);
  }
}
@keyframes rot-3 {
  from {
    transform: rotate(0deg) translate(30.90169943749471%) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(30.90169943749471%) rotate(-360deg);
  }
}
@-webkit-keyframes rot-4 {
  from {
    transform: rotate(0deg) translate(30.90169943749471%) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(30.90169943749471%) rotate(-360deg);
  }
}
@keyframes rot-4 {
  from {
    transform: rotate(0deg) translate(30.90169943749471%) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(30.90169943749471%) rotate(-360deg);
  }
}
@-webkit-keyframes rot-5 {
  from {
    transform: rotate(0deg) translate(30.90169943749471%) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(30.90169943749471%) rotate(-360deg);
  }
}
@keyframes rot-5 {
  from {
    transform: rotate(0deg) translate(30.90169943749471%) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(30.90169943749471%) rotate(-360deg);
  }
}
@-webkit-keyframes rot-6 {
  from {
    transform: rotate(0deg) translate(30.90169943749471%) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(30.90169943749471%) rotate(-360deg);
  }
}
@keyframes rot-6 {
  from {
    transform: rotate(0deg) translate(30.90169943749471%) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(30.90169943749471%) rotate(-360deg);
  }
}
</style>
