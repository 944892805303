<template>
  <div class="container">
    <div class="row works-header">
      <div
        class="col-12 col-md-3 txt-start d-grid align-items-center works-header-left"
      >
        <h1 class="pb-2 pb-md-3 text-start">作品總覽</h1>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
      <div
        class="col-12 col-md-7 d-flex justify-content-end align-items-end pe-4 works-header-right"
      >
        <select
          class="form-select w-50 work-select"
          aria-label="Default select example"
          @change="filter"
          v-model="category"
        >
          <option selected>全部</option>
          <option
            v-for="(category, index) in categories"
            :key="index"
            :value="category"
            >{{ category }}</option
          >
        </select>
      </div>
      <div class="col-md-2 d-none"></div>
    </div>

    <section id="works" class="row">
      <div class="col-md-2 d-none d-md-block pt-4 position-relative">
        <img
          class="w-50 h-auto position-sticky"
          style="filter: none"
          src="@/assets/logoName.png"
        />
      </div>
      <div class="col-12 col-md-8">
        <h1 class="d-block d-md-none">WORKS</h1>
        <div class="row">
          <div
            class="col-12 col-sm-6 col-md-4 p-4"
            v-for="(work, index) in works"
            :key="index"
            :class="{ 'd-none': !work.display }"
          >
            <img :src="work.img" />
            <h3 class="pt-4 text-break">{{ work.name }}</h3>
            <h4>{{ work.description }}</h4>
          </div>
        </div>
      </div>
      <div class="col-md-2 d-none d-md-block pt-4">
        <span class="works-sign">WORKS</span>
      </div>
    </section>
  </div>
</template>

<script>
import works from "../data/works.json";

export default {
  name: "Works",
  data: () => {
    let categories = [];
    works.forEach((work) => {
      work.img = require("../assets/works/" + work.img);
      work.display = true;
      categories.push(work.name);
    });
    categories = [...new Set(categories)];
    return {
      category: "全部",
      categories,
      works,
    };
  },
  methods: {
    filter() {
      this.works.map((work) => {
        work.display = true;
        if (this.category !== "全部" && work.name !== this.category)
          work.display = false;
        console.log(work.name, this.category);
      });
      console.log(this.works);
    },
  },
};
</script>

<style scoped>
.works-header {
  background: radial-gradient(
    26vw circle at 16vw 28vw,
    white 100%,
    transparent 0%
  );
  padding-bottom: 22vw;
  margin-bottom: -28vw;
  padding-top: 18vw;
}
.works-header-left {
  padding-left: 5vw;
}
.works-header-right {
  margin-bottom: -18vw;
}
.works-header-left h1 {
  color: black;
  font-size: 1.375rem;
}
.dot {
  height: 0.6rem;
  width: 0.6rem;
  background-color: black;
  border-radius: 50%;
  display: block;
  margin-bottom: 0.5rem;
  margin-left: 2.75rem;
}
.work-select {
  background-image: url("../assets/arrow-down.png"), linear-gradient(to right, #e9954e, #e9954e);
  background-position: calc(100% - 8px) 10px, 108% 0;
  background-size: 1em 1em, 2.5em 100%;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  border: none;
}
#works {
  padding-top: 300px;
  margin-top: -250px;
  margin-bottom: 200px;
  color: white;
}
#works .position-sticky {
  top: 0;
  margin-bottom: 200px;
}
#works img {
  width: 100%;
  height: calc(90vw - 3rem);
  filter: grayscale(100%);
  border-radius: 10px;
  transition: 0.5s;
}
#works img:hover {
  filter: none;
}
#works h3,
#works h4 {
  text-align: left;
  letter-spacing: 2.5px;
}
#works h4 {
  font-weight: 300;
  color: #ccc;
}
#works .works-sign {
  writing-mode: vertical-rl;
  font-size: 10rem;
  color: #3397a5;
  font-weight: 800;
  line-height: 10rem;
  letter-spacing: 1.5rem;
  margin-right: -60%;
  font-family: "Poppins", sans-serif;
}
#works .btn {
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  background-color: #222;
  padding: 17px 60px;
  margin: 0 auto;
  margin-top: 45px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
#works .btn span {
  position: relative;
  z-index: 1;
}
#works .btn:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 490%;
  width: 140%;
  background: #3397a5;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
  transform: translateX(-98%) translateY(-25%) rotate(45deg);
}
#works .btn:hover:after {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

@media (min-width: 320px) {
  #works img {
    height: calc(90vw - 3rem);
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 767px;
  }
  .works-header {
    background: radial-gradient(
      18vw circle at 16vw 18vw,
      white 100%,
      transparent 0%
    );
    padding-bottom: 16vw;
    margin-bottom: -32vw;
    padding-top: 12vw;
  }
  .works-header-left {
    padding-left: 9vw;
  }
  .works-header-left h1 {
    font-size: calc(1.375rem + 0.7vw) !important;
  }
  .work-select {
    background-size: 1em 1em, 3em 100%;
  }
  #works img {
    height: calc(40vw - 3rem);
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 991px;
  }
  .works-header {
    background: radial-gradient(
      18vw circle at 16vw 18vw,
      white 100%,
      transparent 0%
    );
    padding-bottom: 16vw;
    margin-bottom: -32vw;
    padding-top: 12vw;
  }
  .works-header-left {
    padding-left: 9vw;
  }
  .works-header-left h1 {
    font-size: calc(1.375rem + 0.5vw) !important;
  }
  .dot {
    margin-left: calc((1.375rem + 0.5vw)*2);
  }
  .works-header-right {
    margin-bottom: -12vw;
  }
  #works {
    background: radial-gradient(
      100vw circle at 70vw 75vw,
      #333 70%,
      transparent 0%
    );
    padding-bottom: 16vw;
    margin-bottom: -16vw;
  }
  #works img {
    height: calc(22.22vw - 3rem);
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1199px;
  }
  .works-header {
    background: radial-gradient(
      16vw circle at 14vw 16vw,
      white 100%,
      transparent 0%
    );
    padding-bottom: 16vw;
    margin-bottom: -26vw;
    padding-top: 10vw;
  }
  .works-header-left {
    padding-left: 7vw;
  }
  .works-header-left h1 {
    font-size: calc(1.375rem + 1.4vw) !important;
  }
  .dot {
    margin-left: calc((1.375rem + 1.4vw)*2);
  }
  .works-header-right {
    margin-bottom: -12vw;
  }
  .work-select {
    background-image: url("../assets/arrow-down.png"),
      linear-gradient(to right, #e9954e, #e9954e);
    background-position: calc(100% - 15px) 10px, 100% 0;
    background-size: 1.3em 1.3em, 2.5em 100%;
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    border: none;
  }
  #works {
    background: radial-gradient(
      100vw circle at 70vw 70vw,
      #333 70%,
      transparent 0%
    );
    padding-bottom: 20vw;
    margin-bottom: -20vw;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
  .works-header {
    background: radial-gradient(
      14vw circle at 12vw 14vw,
      white 100%,
      transparent 0%
    );
    padding-bottom: 14vw;
    margin-bottom: -20vw;
    padding-top: 10vw;
  }
  .works-header-left {
    padding-left: 7vw;
  }
  .works-header-left h1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .dot {
    margin-left: calc((1.375rem + 1.5vw)*2);
  }
  .works-header-right {
    margin-bottom: -10vw;
  }
  #works {
    background: radial-gradient(
      90vw circle at 50vw 65vw,
      #333 70%,
      transparent 0%
    );
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1920px;
  }
  .works-header {
    background: radial-gradient(
      12vw circle at 10vw 12vw,
      white 100%,
      transparent 0%
    );
    padding-bottom: 12vw;
    margin-bottom: -18vw;
    padding-top: 8vw;
  }
  .works-header-left {
    padding-left: 5vw;
  }
  .works-header-right {
    margin-bottom: -8vw;
  }
  #works {
    background: radial-gradient(
      90vw circle at 40vw 65vw,
      #333 70%,
      transparent 0%
    );
  }
}
@media (min-width: 1800px) {
  .works-header {
    background: radial-gradient(
      180px circle at 228px 180px,
      white 100%,
      transparent 0%
    );
    padding-bottom: 180px;
    margin-bottom: -288px;
    padding-top: 108px;
  }
  .works-header-right {
    margin-bottom: -108px;
  }
  .works-header-left {
    padding-left: 0;
    justify-content: center;
  }
  .works-header-left h1 {
    font-size: 56px !important;
  }
  .dot {
    margin-left: 112px;
  }
  #works {
    background: radial-gradient(
      1350px circle at 50% 950px,
      #333 70%,
      transparent 0%
    );
  }
  #works img {
    height: 378.65px;
  }
}
</style>
