<template>
  <div class="container">
    <div class="row top">
      <div class="col-12 col-md-6 top-title text-center text-md-start">
        <h1>勝輝鋼鐵加工</h1>
        <h1>
          您最好的選擇
          <hr class="d-none d-md-block" />
        </h1>
        <h1
          style="font-family: 'Great Vibes', cursive; font-size: calc(3rem + 1.5vw); color: #999; margin-top: 20px;"
        >
          Best Choice
        </h1>
      </div>
      <div class="col-5 col-md-6 d-none d-md-block">
        <Circle />
      </div>
      <div class="col-1 d-none d-lg-block"></div>
    </div>

    <section id="works" class="row">
      <div class="col-md-2 d-none d-md-block pt-4 position-relative">
        <img
          class="w-50 h-auto position-sticky"
          style="filter: none"
          src="@/assets/logoName.png"
        />
      </div>
      <div class="col-12 col-md-8">
        <h1 class="d-block d-md-none">WORKS</h1>
        <div class="row">
          <div
            class="col-12 col-sm-6 col-md-4 p-4"
            v-for="(work, index) in works"
            :key="index"
          >
            <img :src="work.img" />
            <h3 class="pt-4">{{ work.name }}</h3>
            <h4>{{ work.description }}</h4>
          </div>
        </div>
      </div>
      <div class="col-md-2 d-none d-md-block pt-4">
        <span class="works-sign">WORKS</span>
      </div>
      <div class="col-12">
        <a class="works-btn" href="/works"><button class="btn"><span>More Works</span></button></a>
      </div>
    </section>

    <section id="news" class="row">
      <div class="col-md-2 d-none d-md-block">
        <span class="news-sign">NEWS</span>
      </div>
      <div class="col-12 col-md-7 text-start">
        <h1 class="d-block d-md-none">NEWS</h1>
        <ul class="p-0">
          <li
            class="d-flex align-items-center pt-3"
            v-for="(n, i) in news"
            :key="i"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            @click="setNews(n)"
          >
            <h3 class="d-inline-block">{{ n.date }}</h3>
            <h3 class="d-table ps-3">{{ n.title }}</h3>
          </li>
        </ul>
      </div>
      <div class="col-md-3 d-none d-md-block"></div>
    </section>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      v-if="newsClicked"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title me-2" id="exampleModalLabel">{{ newsClicked.title }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" v-html="newsClicked.content">
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Circle from "../components/Circle.vue";
import news from "../data/news.json";
import works from "../data/works.json";

export default {
  name: "Home",
  components: {
    Circle,
  },
  data: () => {
    works.forEach((work) => {
      work.img = require("../assets/works/" + work.img);
    });
    return {
      works,
      news: news.slice(0, 5),
      newsClicked: news[0]
    };
  },
  methods: {
    setNews: function (n) {
      console.log(n)
      this.newsClicked = n
    }
  },
};
</script>

<style scoped>
.top {
  margin-top: 60px;
}
.top-title {
  padding-top: 100px;
  color: white;
  position: relative;
}
.top-title h1 {
  font-size: calc(1.5rem + 1.5vw);
  letter-spacing: 5px;
}
.top-title hr {
  height: 1px;
  position: absolute;
  margin-top: -10px;
  border: 0;
  border-top: 3px solid white;
  opacity: 1;
}

#works {
  padding-top: 300px;
  margin-top: -150px;
  color: white;
}
#works .position-sticky {
  top: 0;
}
#works img {
  width: 100%;
  height: calc(90vw - 3rem);
  filter: grayscale(100%);
  border-radius: 10px;
  transition: 0.5s;
}
#works img:hover {
  filter: none;
}
#works h3,
#works h4 {
  text-align: left;
  letter-spacing: 2.5px;
}
#works h4 {
  font-weight: 300;
  color: #ccc;
}
#works .works-sign {
  writing-mode: vertical-rl;
  font-size: 10rem;
  color: #3397a5;
  font-weight: bold;
  line-height: 10rem;
  letter-spacing: 1.5rem;
  margin-right: -60%;
}
#works .btn {
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  background-color: #222;
  padding: 17px 60px;
  margin: 0 auto;
  margin-top: 45px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
#works .works-btn {
  color: white;
  text-decoration: none;
}
#works .btn span {
  position: relative;
  z-index: 1;
}
#works .btn:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 490%;
  width: 140%;
  background: #3397a5;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
  transform: translateX(-98%) translateY(-25%) rotate(45deg);
}
#works .btn:hover:after {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

#news {
  margin-top: 150px;
  margin-bottom: 150px;
}
#news li {
  list-style: none;
  cursor: pointer;
}

#news li:hover h3 {
  color: #666;
}

.modal-content {
  background-color: #2a2a2a;
  padding: 2rem;
}
.modal-header, .modal-title {
  font-size: 1.75rem;
  letter-spacing: 3.5px;
  line-height: 2.5rem;
  text-align: left;
}
.modal-header {
  align-items: flex-start;
  color: white;
  border-color: #666;
}
.btn-close {
  filter: invert(1);
}
.modal-body {
  font-size: 1.25rem;
  letter-spacing: 2.5px;
  text-align: left;
  line-height: 2rem;
  color: #ccc;
  line-break: anywhere;
}
.modal-footer {
  border: none;
}

.modal-footer .btn {
  background-color: #3397a5;
  border: none;
  padding: 0.75rem;
  letter-spacing: 5px;
  font-weight: 300;
  font-size: 1rem;
}

.works-sign, .news-sign {
  font-family: "Poppins", sans-serif;
  font-weight: 800 !important;
}
@media (min-width: 320px) {
  #works {
    background: none;
  }
  #works img {
    height: calc(90vw - 3rem);
  }

  #works h1,
  #news h1 {
    text-align: left;
    font-size: 4rem;
    color: #78c7d2;
    letter-spacing: 8px;
  }
  #news h1 {
    color: #e9954e;
  }
  #news h3 {
    color: white;
    line-height: 1.8rem;
  }
}
@media (min-width: 576px) {
  #works {
    background: none;
  }
  #works img {
    height: calc(40vw - 3rem);
  }

  #works h1,
  #news h1 {
    text-align: left;
    font-size: 5rem;
    color: #78c7d2;
    letter-spacing: 8px;
  }
  #news h1 {
    color: #e9954e;
  }
  #news h3 {
    color: white;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 991px;
  }
  .top {
    padding-left: 60px;
    padding-right: 60px;
  }
  .top-title hr {
    width: 40%;
    right: -15%;
  }
  #works {
    padding-top: 200px;
    margin-top: -100px;
    padding-bottom: 20vw;
    background: radial-gradient(
      110vw circle at 45vw 78vw,
      #2a2a2a 70%,
      transparent 0%
    );
  }
  #works img {
    height: calc(22.22vw - 3rem);
  }

  #news {
    padding-top: 40vw;
    margin-top: -20vw;
    height: 100vw;
    background: radial-gradient(
      70vw circle at 35% 50vw,
      #b3b3b3 70%,
      transparent 0%
    );
  }
  #news .news-sign {
    writing-mode: vertical-rl;
    font-size: 10rem;
    color: #e9954e;
    font-weight: bold;
    line-height: 10rem;
    letter-spacing: 1.5rem;
    margin-top: -20vw;
    margin-left: -20%;
  }
  #news h3 {
    color: black;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1199px;
  }
  .top {
    padding-left: 160px;
    padding-right: 160px;
  }
  .top-title hr {
    width: 33%;
    right: -15%;
  }
  #works {
    padding-top: 200px;
    margin-top: -100px;
    padding-bottom: 22vw;
    background: radial-gradient(
      105vw circle at 45vw 75vw,
      #2a2a2a 70%,
      transparent 0%
    );
  }

  #news {
    background: radial-gradient(
      65vw circle at 35% 50vw,
      #b3b3b3 70%,
      transparent 0%
    );
  }

  .modal-dialog {
    max-width: 600px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
  .top {
    padding-left: 180px;
    padding-right: 180px;
  }
  .top-title hr {
    width: 40%;
    right: -12%;
  }
  #works {
    padding-top: 30vw;
    margin-top: -25vw;
    background: radial-gradient(
      85vw circle at 50% 78vw,
      #2a2a2a 70%,
      transparent 0%
    );
    padding-bottom: 12vw;
  }

  #news {
    padding-top: 30vw;
    margin-top: -10vw;
    height: 80vw;
    background: radial-gradient(
      50vw circle at 30vw 35vw,
      #b3b3b3 70%,
      transparent 0%
    );
  }
  #news .news-sign {
    margin-top: -15vw;
    margin-left: -20%;
  }

  .modal-dialog {
    max-width: 800px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1920px;
  }
  .top-title hr {
    width: 45%;
    right: -10%;
  }
  #works {
    padding-top: 35vw;
    margin-top: -30vw;
    background: radial-gradient(
      85vw circle at 50% 78vw,
      #2a2a2a 70%,
      transparent 0%
    );
    padding-bottom: 13vw;
  }
  #news {
    padding-top: 30vw;
    margin-top: -15vw;
    height: 80vw;
    background: radial-gradient(
      45vw circle at 30vw 35vw,
      #b3b3b3 70%,
      transparent 0%
    );
  }
}
@media (min-width: 1800px) {
  #works {
    padding-top: 250px;
    margin-top: -150px;
    padding-bottom: 150px;
    background: radial-gradient(
      1350px circle at 50% 950px,
      #2a2a2a 70%,
      transparent 0%
    );
  }
  #works img {
    height: 378.65px;
  }

  #news {
    padding-top: 550px;
    margin-top: -380px;
    height: 1050px;
    background: radial-gradient(
      600px circle at 27% 60%,
      #b3b3b3 70%,
      transparent 0%
    );
  }
  #news .news-sign {
    margin-top: -200px;
  }
}
</style>
